<template>
  <div id="clothSearch" class="clothSearch">
    <!-- 顶部筛选 -->
    <div class="filter">
      <div class="left">
        <div class="filterItem">
          <div style="text-align: right;margin-right:10px">货号/条形码</div>
          <Input placeholder="请输入搜索内容" v-model.trim="barcode" style="width: 210px" @keyup.enter.native="handleChangeFilter"/>
        </div>
        <div class="filterItem">
          <div style="text-align: right;margin-right:10px">品名</div>
          <Input placeholder="请输入搜索内容" v-model.trim="purchaseName" style="width: 210px" @keyup.enter.native="handleChangeFilter"/>
        </div>
        <div class="filterItem">
          <div style="text-align: right;margin-right:10px">颜色</div>
          <Input placeholder="请输入搜索内容" v-model.trim="color" style="width: 210px" @keyup.enter.native="handleChangeFilter"/>
        </div>
      <div class="filterItem">
        <Button @click="handleClickfilter">
          自定义筛选
          <i v-if="!showUserDefined" class="iconfont iconicon-xia"></i>
          <i v-if="showUserDefined" class="iconfont iconicon-shang"></i>
        </Button>
      </div>
      </div>
    </div>
    <!--自定义筛选显示-->
    <div class="userDefined" v-if="showUserDefined">
      <div class="left">
        <div class="select_container" v-if="showSelect" style="margin-bottom:10px;">
          <Checkbox
            :indeterminate="indeterminate1"
            :value="checkAll1"
            @click.prevent.native="handleCheckAll1"
          >全选</Checkbox>
          <div class="divide"></div>
          <CheckboxGroup v-model="checkAllGroup1" @on-change="checkAllGroupChange1">
            <Checkbox :label="item.name" v-for="item in boxList1" :key="item.name"></Checkbox>
          </CheckboxGroup>
        </div>
        <div class="filter_container" v-else>
          <div class="filterItem" v-if="checkAllGroupShow1.includes('花型')">
            <div style="line-height:32px;margin-right:10px">花型</div>
            <Input placeholder="请输入搜索内容" v-model.trim="designType" style="width: 210px" @keyup.enter.native="handleChangeFilter"/>
          </div>
          <div class="filterItem" v-if="checkAllGroupShow1.includes('采购单号')">
            <div style="line-height:32px;margin-right:10px">采购单号</div>
            <Input placeholder="请输入搜索内容" v-model.trim="purchaseOrderNum" style="width: 210px" @keyup.enter.native="handleChangeFilter"/>
          </div>
          <div class="filterItem" v-if="checkAllGroupShow1.includes('合同号')">
            <div style="line-height:32px;margin-right:10px">合同号</div>
            <Input placeholder="请输入搜索内容" v-model.trim="contractNum" style="width: 210px" @keyup.enter.native="handleChangeFilter"/>
          </div>
        </div>
      </div>
      <div class="right">
        <!-- <Button class="cancel" v-if="showSelect">取消</Button> -->
        <Button type="primary" v-if="showSelect" @click="handleClickConfirm">确定</Button>
      </div>
    </div>
    <!-- 状态单选 -->
    <!-- <div class="box">
      <div class="boxItem">
        <span style="margin-right: 10px">状态：</span>
        <Checkbox
          :indeterminate="indeterminate2"
          :value="checkAll2"
          @click.prevent.native="handleCheckAll2"
          >全选</Checkbox
        >
        <CheckboxGroup v-model="checkAllGroup2" @on-change="checkAllGroupChange2">
          <Checkbox
            :label="item.name"
            v-for="item in boxList2"
            :key="item.name"
          ></Checkbox>
        </CheckboxGroup>
      </div>

    </div>-->

    <!-- 按钮 -->
    <div class="button">
      <div class="buttonRow">
        <div class="left">
          <div class="item" @click="syncTableData" >
            <i class="iconfont iconicon-tongbu" />
            同步
          </div>
          <div class="item" @click="handleClickExport">
            <i class="iconfont iconicon-daochu" />
            导出
          </div>
          <div class="divide"></div>
          <div class="item" @click="handleClickTableSetting('main')">
            <i class="iconfont iconicon-chuansuokuang" />
            表格设置
          </div>
          <div class="item" @click="tableDataList1">
            <i class="iconfont iconquerenshaixuan"></i>
            确认筛选
          </div>
        </div>
      </div>
      <!-- 可以扩展  -->
    </div>

    <!-- 表格 -->
    <Table :columns="tableColumns1" :loading="loading1" :data="tableData1" border show-summary :summary-method="handleSummary1"></Table>

    <!-- 分页 -->
    <Page
      class-name="page"
      :total="listData1.total"
      show-elevator
      show-sizer
      show-total
      :page-size="listData1.pageSize"
      :current="listData1.pageNum"
      @on-page-size-change="pageSizeChange1"
      @on-change="pageNumberChange1"
    />
    <!-- 下方的抽屉弹窗 -->
    <!-- <el-drawer title="进出仓明细表" size="400px" :visible.sync="isShowDrawer" direction="btt">
      <div>
        <div class="drawer_header">
          <div class="left">
            <div class="item">
              <Icon type="md-refresh" size="20" />刷新
            </div>
            <div class="item">
              <Icon type="md-refresh" size="20" />补单
            </div>
            <div class="item" @click="handleClickTableSetting('detail')">
              <Icon type="md-refresh" size="20" />表格设置
            </div>
          </div>
          <div class="right">
            <div class="boxItem">
              <span style="margin-right: 10px">状态：</span>
              <Checkbox
                :indeterminate="indeterminate3"
                :value="checkAll3"
                @click.prevent.native="handleCheckAll3"
              >全选</Checkbox>
              <CheckboxGroup v-model="checkAllGroup3" @on-change="checkAllGroupChange3">
                <Checkbox :label="item.name" v-for="item in boxList3" :key="item.name"></Checkbox>
              </CheckboxGroup>
            </div>
          </div>
        </div>
        <Table :columns="tableColumns2" :data="tableData2" border></Table> -->
        <!-- 分页 -->
        <!-- <Page
          class-name="page"
          :total="listData2.total"
          show-elevator
          show-sizer
          show-total
          :page-size="listData2.pageSize"
          :current="listData2.pageNum"
          @on-page-size-change="pageSizeChange"
        /> -->
      <!-- </div>
    </el-drawer> -->
    <TableSetting
      :chuansuoDialogVisible.sync="chuansuoDialogVisible"
      :routerPath="routerPath"
      :theadDateReal="theadDateReal"
      @tableDataList="tableDataList"
    />
  </div>
</template>

<script>
import TableSetting from "../../components/home/tableSetting.vue";
export default {
  components: {
    TableSetting
  },
  data() {
    return {
      loading1: false, // 是否在读取中
      clothQuantitySum: 0, // 匹数合计
      totalNum: 0, // 数量合计
      barcode: '', // 货号/条形码
      purchaseName: '', // 采购品名
      color: '', // 颜色
      designType: '', // 花型
      purchaseOrderNum: '', // 采购单号
      contractNum: '', // 合同号
      chuansuoDialogVisible: false, // 穿梭框是否显示
      routerPath: "/dtsum/zongtong/greycloth/greyClothController/clothSheetSearch",
      theadDateReal: "",
      tableDataList: "",

      isShowDrawer: true, // 是否展示下方的抽屉弹窗
      filterList: [
        // 条件筛选的列表
        {
          number: 1,
          name: "工单单号"
        },
        {
          number: 2,
          name: "订单单号"
        }
      ],
      showUserDefined: false, // 是否展示自定义筛选模块
      showSelect: false, // true展示多选框，false展示筛选条件

      indeterminate1: false, // 只负责样式控制,true为是
      checkAll1: false, // 是否全选
      checkAllGroup1: [], // 已选的多选框的存储
      checkAllGroupShow1: [], // 筛选条件已选的存储

      boxList1: [
        // 多选框的列表
        {
          name: "花型"
        },
        {
          name: "采购单号"
        },
        {
          name: "合同号"
        }
      ],

      indeterminate2: false, // 只负责样式控制,true为是
      checkAll2: false, // 是否全选
      checkAllGroup2: [], // 已选的多选框的存储

      boxList2: [
        // 多选框的列表
        {
          name: "待穿纱"
        },
        {
          name: "待改机"
        },
        {
          name: "量产中"
        }
      ],
      indeterminate3: false, // 只负责样式控制,true为是
      checkAll3: false, // 是否全选
      checkAllGroup3: [], // 已选的多选框的存储
      boxList3: [
        // 多选框的列表
        {
          name: "待穿纱"
        },
        {
          name: "待改机"
        },
        {
          name: "量产中"
        }
      ],
      tableColumnsReady1: [
        {
          title: "货号",
          key: "articleNumber",
          minWidth: 100,
          align: "left"
        },
        {
          title: "品名",
          key: "purchaseName",
          minWidth: 150,
          align: "left"
        },
        {
          title: "颜色",
          key: "color",
          minWidth: 150,
          align: "left"
        },
        {
          title: "花型",
          key: "designType",
          minWidth: 150,
          align: "left"
        },
        {
          title: "匹数",
          key: "clothQuantity",
          minWidth: 80,
          align: "left"
        },
        {
          title: "采购单号",
          key: "purchaseOrderNum",
          minWidth: 80,
          align: "left"
        },
        {
          title: "合同号",
          key: "contractNum",
          minWidth: 80,
          align: "left"
        },
        {
          title: "数量（KG）",
          key: "totalNum",
          minWidth: 150,
          align: "left",
          render: (h, params) => {
            return h('span',
                params.row.totalNum.toFixed(2)
            );
          }
        }
      ],
      tableColumns1: [],
      userFilds1: [],
      tableData1: [], // 主表的数据
      listData1: { // 主表的分页数据
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      tableColumnsReady2: [
        {
          title: "动作",
          key: "movement",
          minWidth: 80,
          align: "left"
        },
        {
          title: "货号",
          key: "articleNumber",
          minWidth: 80,
          align: "left"
        },
        {
          title: "品名",
          key: "purchaseName",
          minWidth: 80,
          align: "left"
        },
        {
          title: "颜色",
          key: "color",
          minWidth: 80,
          align: "left"
        },
        {
          title: "花型",
          key: "designType",
          minWidth: 80,
          align: "left"
        },
        {
          title: "匹数",
          key: "clothQuantity",
          minWidth: 80,
          align: "left"
        },
        {
          title: "数量（KG）",
          key: "totalNum",
          minWidth: 80,
          align: "left",
          render: (h, params) => {
            return h('span',
                params.row.totalNum.toFixed(2)
            );
          }
        },
        {
          title: "存放位置",
          key: "location",
          minWidth: 80,
          align: "left"
        },
        {
          title: "批次",
          key: "batch",
          minWidth: 80,
          align: "left"
        },
        {
          title: "包装方式",
          key: "packageWay",
          minWidth: 80,
          align: "left"
        },
        {
          title: "缸号",
          key: "vatNum",
          minWidth: 80,
          align: "left"
        },
      ],
      tableColumns2: [
        {
          title: "序号",
          type: "index",
          minWidth: 80,
          align: "center"
        },
        {
          title: "操作",
          key: "action",
          minWidth: 100,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  style: {
                    fontSize: "14px",
                    color: "rgb(87, 163, 243)",
                    cursor: "pointer"
                  },
                  on: {
                    click: () => {
                      console.log(params);
                    }
                  }
                },
                "详情"
              )
            ]);
          }
        },
      ],
      tableData2: [], // 详情表的数据
      listData2: { // 主表的分页数据
        pageSize: 0,
        pageNum: 0,
        total: 0
      }
    };
  },
  activated(){
    this.tableDataList1()
  },
  methods: {
    // 同步表格数据
    syncTableData() {
      this.loading1 = true;
      this.axios({
        url: "/dtsum/zongtong/basic/CommonController/synTable",
        method: "get"
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.$Message.success("同步成功！");
            this.tableDataList1();
          } else {
            this.$Message.warning("同步失败！");
          }
          this.loading1 = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 合计显示的内容
    handleSummary1({ columns, data }){
      const sums = {};
                columns.forEach((column, index) => {
                    const key = column.key;
                    if (index === 0) {
                        sums[key] = {
                            key,
                            value: '累计'
                        };
                        return;
                    }
                     data.map(item => Number(item[key]));
                    if (key === 'clothQuantity') {
                        sums[key] = {
                          key,
                          value: this.clothQuantitySum
                        }
                    } else if (key === 'totalNum'){
                      sums[key] = {
                        key,
                        value: this.totalNum
                      }
                    } else {
                        sums[key] = {
                            key,
                            value: ''
                        };
                    }
                });

                return sums;
    },
    // 改变筛选条件
    handleChangeFilter(){
      this.tableDataList1()
    },
    // 主表的导出
    handleClickExport(){
      window.location.href = `${this.baseUrl}/dtsum/zongtong/greycloth/greyClothController/clothSheetExport?barcode=${this.barcode}&purchaseName=${this.purchaseName}&color=${this.color}&designType=${this.designType}`
    },
    // 主表的表格设置
    handleClickTableSetting(value) {
      switch (value) {
        case "main":
          this.routePath =
            "/dtsum/zongtong/greycloth/greyClothController/clothSheetSearch";
          this.theadDateReal = this.tableColumns1;
          this.tableDataList = this.tableDataList1;
          break;
        case "detail":
          this.routePath =
            "/dtsum/zongtong/greycloth/greyClothController/clothSheetDetail";
          this.theadDateReal = this.tableColumns1;
          this.tableDataList = this.tableDataList2;
          break;
        default:
          this.routePath =
            "/dtsum/zongtong/greycloth/greyClothController/clothSheetSearch";
          this.theadDateReal = this.tableColumns1;
          this.tableDataList = this.tableDataList1;
      }
      this.chuansuoDialogVisible = true;
    },
    // 获取主表的信息
    tableDataList1() {
      this.loading1 = true;
      this.axios({
        method: "get",
        url: "/dtsum/zongtong/greycloth/greyClothController/clothSheetSearch",
        params: {
          pageSize: this.listData1.pageSize, // 页大小
          pageNum: this.listData1.pageNum, // 页号

          params: {
            barcode: this.barcode, // 货号/条形码
            purchaseName: this.purchaseName, // 采购品名
            color: this.color, // 颜色
            designType: this.designType, // 花型
            purchaseOrderNum: this.purchaseOrderNum, // 采购单号
            contractNum: this.contractNum, // 合同号
          }
        }
      }).then((res) => {
        this.loading1 = false;
        if (res.data.success === 1){
          const clothSheetList = res.data.body.clothSheetList;
          this.tableData1 = clothSheetList.list;
          console.log(this.tableData1)
          console.log(res);
          this.userFilds1 = res.data.body.userFilds;
          this.clothQuantitySum = res.data.body.clothQuantitySum;
          this.totalNum = Number(res.data.body.totalNum).toFixed(2);
          this.listData1.pageSize = clothSheetList.pageSize;
          this.listData1.pageNum = clothSheetList.pageNum;
          this.listData1.total = clothSheetList.total;
          this.tableColumns1 = [
            {
          title: "序号",
          type: "index",
          width: 80,
          align: "center",
          render: (h, params) => {
            return h(
              "span",
              params.index +
                (this.listData1.pageNum - 1) * this.listData1.pageSize +
                1
            );
          },
        },
          ]
          this.userFilds1.map((item) => {
            this.tableColumnsReady1.map((itemIn) => {
              if (itemIn.key === item.field){
                this.tableColumns1.push(itemIn)
              }
            })
          })
        }
      }).catch((err) => {
        this.loading1 = false
        console.log(err)
      })
      ;
    },
    // 获取详情表的信息
    tableDataList2() {
      this.axios({
        method: 'get',
        url: '/dtsum/zongtong/greycloth/greyClothController/clothSheetDetail',
        params: {
          id: 0, // 唯一id
          entryOrOutput: 0, // 进出仓,0表示进出仓，1表示进仓，2表示出仓

          pageSize: 10, // 页大小
          pageNum: 1, // 页号
        }
      }).then((res) => {
        if (res.data.success === 1){
          this.tableData2 = JSON.parse(JSON.stringify(res.data.body.clothSheetDetailList))
        }
      })
    },
    // 自定义筛选
    handleClickfilter() {
      this.showSelect = !this.showSelect;
      if (this.showSelect === true) {
        // 从筛选条件跳转到多选框
        this.showUserDefined = true;
        this.checkAllGroup1 = this.checkAllGroupShow1;
      } else {
        // 从多选框跳到筛选条件
        if (this.checkAllGroupShow1.length === 0) {
          this.showUserDefined = false;
        }
      }
    },
    // 确认多选框跳转到筛选条件
    handleClickConfirm() {
      this.showSelect = false;
      this.checkAllGroupShow1 = this.checkAllGroup1;
      if (this.checkAllGroupShow1.length === 0) {
        this.showUserDefined = false;
      }
    },
    // 跳转到筛选条件的多选框
    handleClickSelectButton() {
      this.showSelect = true;
    },
    pageSizeChange1(pageSize) {
      this.listData1.pageSize = pageSize;
      this.listData1.pageNum = 1;
      this.tableDataList1()
    },
    pageNumberChange1(pageNum) {
      this.listData1.pageNum = pageNum;
      this.tableDataList1()
    },
    handleCheckAll1() {
      // 全选
      if (this.indeterminate1) {
        this.checkAll1 = false;
      } else {
        this.checkAll1 = !this.checkAll1;
      }
      this.indeterminate1 = false;

      if (this.checkAll1) {
        this.checkAllGroup1 = ['花型', '采购单号', '合同号']; // 这里改为全部的状态
      } else {
        this.checkAllGroup1 = [];
      }
    },
    checkAllGroupChange1(data) {
      if (data.length === 3) {
        this.indeterminate1 = false;
        this.checkAll1 = true;
      // } else if (data.length > 0) {
      //   this.indeterminate1 = true;
      //   this.checkAll1 = false;
      } else {
        this.indeterminate1 = false;
        this.checkAll1 = false;
      }
    },
    handleCheckAll2() {
      // 全选
      if (this.indeterminate2) {
        this.checkAll2 = false;
      } else {
        this.checkAll2 = !this.checkAll2;
      }
      this.indeterminate2 = false;

      if (this.checkAll2) {
        this.checkAllGroup2 = ["待穿纱", "待改机", "量产中"]; // 这里改为全部的状态
      } else {
        this.checkAllGroup2 = [];
      }
    },
    checkAllGroupChange2(data) {
      if (data.length === 3) {
        this.indeterminate2 = false;
        this.checkAll = true;
      } else if (data.length > 0) {
        this.indeterminate2 = true;
        this.checkAll = false;
      } else {
        this.indeterminate2 = false;
        this.checkAll = false;
      }
    },
    handleCheckAll3() {
      // 全选
      if (this.indeterminate3) {
        this.checkAll3 = false;
      } else {
        this.checkAll3 = !this.checkAll2;
      }
      this.indeterminate3 = false;

      if (this.checkAll3) {
        this.checkAllGroup3 = ["待穿纱", "待改机", "量产中"]; // 这里改为全部的状态
      } else {
        this.checkAllGroup3 = [];
      }
    },
    checkAllGroupChange3(data) {
      if (data.length === 3) {
        this.indeterminate3 = false;
        this.checkAll = true;
      } else if (data.length > 0) {
        this.indeterminate3 = true;
        this.checkAll = false;
      } else {
        this.indeterminate3 = false;
        this.checkAll = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#clothSearch {
  font-size: $--fontsize-title;
  .filter {
    font-size: $--fontsize-small;
    display: flex;
    position: relative;
    .left {
      width:100%;
      .filterItem {
        display: inline-flex;
        margin: 0 50px 10px 0;
        div {
          line-height: 32px;
        }
        &:last-of-type{
          float: right;
        }
      }
    }
  }
  .userDefined {
     background: #F4F7FE;
    padding: 16px 8px;
    font-size: $--fontsize-small;
    border-radius: $--radius-standard;
    position: relative;
    .divide{
      display: inline-block;
      width:1px;
      height:10px;
      margin-top:2px;
      margin-right:10px;
      background-color: #dcdada;
    }
    .left {
      .filterItem {
        display: flex;
        margin: 0 50px 10px 0;
      }
    }
     .filter_container{
      display: flex;
      flex-wrap: wrap;
    }
    .right {
        margin-top: 17px;
        text-align: right;
    }
  }
  .box {
    font-size: $--fontsize-small;
    .boxItem {
      margin: 10px 0;
    }
  }
  .button {
    font-size: $--fontsize-small;
    .divide{
      display: inline-block;
      width:1px;
      height:10px;
      margin-top:2px;
      margin-right:30px;
      background-color: #dcdada;
    }
    .buttonRow {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      color:#256de6;
      .item {
        display: inline-block;
        cursor: pointer;
      }
      .left {
        .item {
          margin-right: 30px;
        }
      }
      .right {
        .item {
          margin-left: 10px;
        }
        .filterButton{
          width:120px;
          margin-right:30px;
          height:32px;
          line-height:32px;
          text-align: center;
        }
      }
    }
  }
  .page {
    font-size: $--fontsize-small;
    text-align: center;
    margin-top: 10px;
  }
  .drawer_header {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular;
    margin-bottom: 10px;
    .left {
      display: flex;
      .item {
        margin-right: 10px;
      }
    }
  }
}
</style>
<style lang="scss">
#clothSearch {
  .filter {
    .right {
      .ivu-btn {
        position: absolute;
        bottom: 10px;
        color: $--color-blue-standard;
      }
    }
  }
  .userDefined {
    .left{
        .ivu-checkbox-wrapper{
        margin-right: 12px;
      }
    }
    .right {
      .cancel {
        position: absolute;
        left: 0;
        bottom: 5px;
      }
      .confirm {
        position: absolute;
        right: 10px;
        bottom: 5px;
      }
      .select_button {
        color: $--color-blue-standard;
        display: flex;
        position: absolute;
        top: calc(50% - 5px);
        transform: translateY(-50%);
        &::before {
          content: "";
          display: block;
          width: 2px;
          height: 20px;
          margin-right: 10px;
          background-color: #dcdada;
        }
      }
    }
  }
  .el-drawer__body {
    padding: 0 20px;
  }
  .ivu-select-selection {
    height: 32px !important;
  }
  .ivu-select-placeholder {
    line-height: 32px !important;
    height: 32px !important;
  }
  .ivu-select-selected-value {
    line-height: 32px !important;
    height: 32px !important;
  }
  .ivu-input {
    height: 32px !important;
  }
  .ivu-input-suffix i {
    line-height: 32px !important;
  }
  .ivu-icon-ios-time-outline {
    line-height: 32px !important;
  }
  .ivu-checkbox-default {
    display: inline-block;
  }
}
</style>
